@import 'styles/common.scss';

.ProfileAuthSessionsPage {
  $root: &;

  &__row {
    &_deleting {
      background-color: #ff440094;
    }
  }
}
