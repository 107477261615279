.ProjectsListPage {
  $root: &;

  &__name {
    margin-bottom: 4px;
  }

  &__slug {
    color: #888;
  }

  &__uniqKeysCol {
    text-align: center;
  }

  &__progress {
    padding: 0 40px 0 0;
  }
}
