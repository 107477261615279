@import 'styles/common.scss';

.KitAction {
  $root: &;
  @extend .action-button;

  & {
    &:not(:disabled):hover {
      transition: background-color 0.25s, color 0.25s;
    }

    &_delete {
      color: #fff;
      background-color: #cf7145;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #c44e18;
      }
    }

    &_edit {
      color: #fff;
      background-color: #2ab3dd;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #1595bd;
      }
    }

    &_versions {
      color: #fff;
      background-color: #26c76e;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #13a856;
      }
    }

    &_info {
      color: #fff;
      background-color: #2eaeb8;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #1b7a81;
      }
    }

    &_export {
      color: #fff;
      background-color: #2eb8a1;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #24c5aa;
      }
    }

    &_import {
      color: #fff;
      background-color: #b87c2e;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #d68821;
      }
    }

    &_create {
      color: #fff;
      background-color: #3dddad;

      &:not(:disabled):hover {
        color: #fff;
        background-color: #1dc08f;
      }
    }

    &_stopCopy {
      background-color: #fff;
      border: 1px solid #e28a57;
      color: #eb9167;

      &:not(:disabled):hover {
        border: 1px solid #c0774c;
        color: #c2794e;
      }
    }

    &_takeBack {
      background-color: #fff;
      border: 1px solid #d4b151;
      color: #ebc867;

      &:not(:disabled):hover {
        border: 1px solid #c9a84f;
        color: #d1a93c;
      }
    }

    &_copy {
      background-color: #fff;
      border: 1px solid #92cddb;
      color: #77d2e4;

      &:not(:disabled):hover {
        border: 1px solid #72abb9;
        color: #32b7d4;
      }
    }

    &_paste {
      background-color: #fff;
      border: 1px solid #8ce6c5;
      color: #91e0bf;

      &:not(:disabled):hover {
        border: 1px solid #73d3af;
        color: #3cbd8e;
      }
    }

    &_text {
      width: auto;
      border-right: 1px solid #ffffff01;
    }
  }

  &__iconCol {
    width: 36px;
  }

  &__text {
    background-color: #fff;
    color: #555;
    display: flex;
    justify-content: center;
    padding: 9px 12px 9px 12px;
    height: 28px;

    font-family: 'Roboto-Regular';
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
