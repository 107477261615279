.ProjectVersionInfo {
  & {
    margin-bottom: 24px;
    padding: 20px;
    border: 1px solid #e1e1e1;
    position: relative;
  }

  &__row {
    margin-bottom: 12px;
  }

  &__paramLabel {
    padding-right: 24px;
    min-width: 120px;
  }

  &__loader {
    position: absolute;
    z-index: 1;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
  }

  &__infoActions {
    display: flex;
  }

  &__infoAction {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__divider {
    &.ant-divider-horizontal {
      margin: 12px 0;
    }
  }

  &__detailRow {
    flex-basis: calc(50% - 24px);
    margin-right: 24px;
    margin-bottom: 12px;
  }

  &__detailColLabel {
    display: inline-block;
    min-width: 120px;
    padding-right: 24px;
  }

  &__detailColValue {
    display: inline-block;
  }
}
