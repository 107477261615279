.clear-button {
  padding: 0;

  color: inherit;
  font: inherit;

  background: none;
  border: none;

  outline: inherit;

  &:not(:disabled) {
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;

      outline: none;
    }
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale(0.9) brightness(1.05);
  }
}

.hover-filter {
  &:not(:disabled) {
    &:hover {
      filter: brightness(1.25) contrast(1.25);
    }
  }
}

.action-button {
  @extend .clear-button;

  width: 36px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  line-height: 16px;
}
