.ProjectLink {
  & {
    margin: 0 8px;
    display: inline-block;
    font-size: 16px;
    color: #0497c4;
    padding: 2px;
    height: 16px;
    min-width: 16px;
    text-align: center;
    line-height: 16px;

    &:hover,
    &:active {
      color: #06b3e7;
    }
  }
}
