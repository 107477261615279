.DashboardStats {
  $root: &;

  & {
    position: relative;
  }

  &__spin {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
  }

  & {
    height: 100%;
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 20px 20px 30px 20px;
  }

  &__container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__statsCol {
    flex: 1;
  }

  &__progressCol {
    flex: 0 0 none;
    padding: 0 20px;
  }

  &__header {
    padding-bottom: 16px;
  }

  &__progress {
    text-align: center;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    display: inline-flex;
    align-items: center;
    width: 360px;
    padding-right: 40px;
  }

  &__param {
    width: 180px;
    font-size: 16px;
    color: #555;
  }

  &__indicator {
    display: inline-block;
    padding: 2px;
    border-radius: 24px;

    min-width: 48px;
    height: 48px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    color: #fff;
    overflow: hidden;

    &_projects {
      border: 2px solid #61ffdd;
      color: #00c096;
    }

    &_versions {
      border: 2px solid #31d6b2;
      color: #0d9b7c;
    }

    &_uniqLocales {
      border: 2px solid #61c0ff;
      color: #61c0ff;
    }

    &_keys {
      border: 2px solid #c07cff;
      color: #7a12db;
    }

    &_messages {
      border: 2px solid #b6ff7b;
      color: #5dc000;
    }

    &_filledMessages {
      border: 2px solid #7bff91;
      color: #00c020;
    }
  }
}
