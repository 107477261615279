.ProjectLocale {
  & {
    display: inline-flex;

    height: 24px;
    align-items: center;
    margin: 0 4px 4px 0;
  }

  &__ico {
    height: 100%;
    width: 36px;
    padding: 4px 1px 4px 1px;
    font-weight: bold;
    color: #fff;
    background-color: #2497da;
    border: 1px solid #2497da;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;

    position: relative;
    z-index: 1;

    @at-root {
      a #{&} {
        color: #2497da;
        background-color: #fff;
        border: 1px solid #2497da;
        transition: color 0.25s, background-color 0.25s;
      }

      a:hover #{&},
      a.active #{&} {
        color: #fff;
        border: 1px solid #2497da;
        background-color: #2497da;
        transition: color 0.25s, background-color 0.25s;
      }
    }
  }

  &__name {
    height: 100%;
    padding: 4px 6px;
    background-color: #fff;
    color: #444;
    line-height: 14px;
    min-width: 64px;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #2497da;
    margin-left: -1px;
    margin-right: 1px;

    @at-root {
      a #{&} {
        border: 1px solid #2497da;
      }

      a:hover #{&},
      a.active #{&} {
        border: 1px solid #2497da;
        color: #2497da;
        transition: color 0.25s;
      }
    }
  }
}
