.KitNavMenu {
  $root: &;

  & {
    margin-bottom: 20px;
  }

  &__list {
    display: block;
    list-style: none;
    padding: 0 0 8px 0;
    margin: 0;
    border-bottom: 2px solid #0084d244;
  }
}
