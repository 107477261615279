.KitActions {
  $root: &;
  & {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  & {
    white-space: nowrap;
  }

  & > button:not(:last-child) {
    margin-right: 4px;
  }
}
