.ManageMessagesPage {
  $root: &;

  &__cellDifferences {
    padding: 0 !important;
    width: 24px;
    text-align: center;
  }

  &__iconDifferences {
    font-size: 24px;
    color: #00442244;
  }

  &__emptyValue {
    background-color: #fb8383;
    display: inline-block;
    height: 16px;
    min-width: 20px;
    border-radius: 4px;
    padding: 2px 4px;
    line-height: 12px;
  }

  &__iconEmptyValue {
    color: #fff;
  }

  &__createdAtDirection {
    text-align: center;
    font-size: 17px;
    line-height: 12px;
    height: 12px;
    font-weight: bold;
    color: #a7a;
  }

  &__pagination {
    padding: 16px 12px;
    min-height: 32px + 16px * 2;
    text-align: right;
  }

  &__keyComment {
    color: #888;
    font-size: 12px;
  }
}
