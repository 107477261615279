@import 'src/styles/common.scss';

.MainLayout {
  $root: &;

  & {
    vertical-align: top;
  }

  &__fadeLine {
    position: fixed;
    z-index: 1;

    &_top {
      top: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.2) 100%
      );
    }

    &_bottom {
      bottom: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.2) 100%
      );
    }

    width: 100vw;
    height: 16px;
  }

  &__sidebar {
    position: fixed;
    z-index: 2;
    width: 280px;

    height: 100vh;
    overflow: hidden;

    background-color: #374953;

    transition: width 0.15s linear;

    @at-root #{$root}_menuClosed #{&} {
      width: 52px;
    }
  }

  &__topPanel {
    width: 100%;
    height: 56px;
    display: flex;
    background-color: #212626;
  }

  &__menuBtn {
    @extend .clear-button;

    width: 52px;
    height: 56px;
    padding: 0 2px 0 0;

    font-size: 24px;
    color: #ddd;
    text-align: center;

    &:hover {
      color: #fff;
    }
  }

  &__logo {
    display: inline-flex;
    height: 56px;
    color: #eee;
    padding: 0 8px;
    align-items: center;

    img {
      opacity: 0.85;
    }

    &:hover {
      color: #fff;

      img {
        opacity: 1;
      }
    }

    img {
      opacity: 0.85;
    }
  }

  &__logoCol {
    flex: 1;

    @at-root {
      #{$root}_menuClosed #{&},
      #{$root}_menuOpened.enter-active #{&} {
        display: none;
      }
    }
  }

  &__logoText {
    padding: 0 0 0 24px;
    font-size: 16px;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    font-family: 'Audiowide-Regular', sans-serif;
    line-height: 14px;
  }

  &__profilePanel {
    display: flex;
    background-color: #13436599;
    height: 60px;
    width: 100%;
    border-top: 1px solid #ffffff24;
    border-bottom: 1px solid #ffffff24;
  }

  &__profileBtn {
    @extend .clear-button;
    display: flex;
    width: 52px;
    flex: 1;
    align-items: center;
    height: 60px;

    color: #ddd;
    font-size: 14px;

    &:hover {
      color: #fff;
      background-color: #00779964;
    }
  }

  &__userIconCol {
    width: 56px;
    padding: 0 1px 0 0;
    flex-basis: 52px;
  }

  &__userIcon {
    font-size: 22px;
  }

  &__userDataCol {
    display: inline-flex;
    flex: 1;
    align-items: center;
    line-height: 14px;
    text-align: left;
    overflow: hidden;

    @at-root {
      #{$root}_menuClosed #{&},
      #{$root}_menuOpened.enter-active #{&} {
        display: none;
      }
    }
  }

  &__userName {
    font-size: 14px;
    padding: 2px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    font-family: 'Roboto-Regular';
  }

  &__userRole {
    margin-bottom: 5px;
    overflow: hidden;
  }

  &__userRoleLabel {
    display: inline-block;
    font-size: 9px;
    height: 15px;
    line-height: 9px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #00777792;
    padding: 3px 8px 3px 8px;
    letter-spacing: 1px;
  }

  &__logoutBtn {
    @extend .clear-button;

    width: 52px;
    height: 100%;
    border-left: 1px solid #ffffff24;

    color: #ddd;
    font-size: 20px;

    &:hover {
      color: #fff;
      background-color: #8c2c5d64;
    }

    @at-root {
      #{$root}_menuClosed #{&},
      #{$root}_menuOpened.enter-active #{&} {
        display: none;
      }
    }
  }

  &__scene {
    margin-left: 280px;
    padding: 16px 16px 52px 20px;

    transition: 0.15s;
    transition-property: margin-left;

    @at-root #{$root}_menuClosed #{&} {
      margin-left: 52px;
    }
  }

  &__menu {
    margin: 0;
    padding: 0;
    list-style: none;
    // border-bottom: 1px solid #ffffff24;
  }

  &__menuLi {
    margin-bottom: 0px;

    &:nth-child(1n) {
      background-color: #00667725;
    }

    &:nth-child(2n) {
      background-color: #00776645;
    }

    border-bottom: 1px solid #ffffff12;
  }

  &__menuLiButton {
    @extend .clear-button;

    display: flex;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    height: 48px;
    color: #ddd;
    text-align: left;
    font-family: 'Roboto-Regular';

    &:not(#{&}_active):hover {
      background-color: #00779964;
    }

    &:hover,
    &_active {
      color: #fff;
    }

    &_active {
      background-color: #8c2c5d64;
    }
  }

  &__menuLiIconCol {
    width: 52px;
    padding: 0 1px 0 0;
    flex-basis: 52px;
    text-align: center;
  }

  &__menuLiIcon {
    font-size: 22px;
  }

  &__menuLiTextCol {
    flex: 1;
    padding: 2px;

    overflow: hidden;
    font-size: 16px;

    @at-root {
      #{$root}_menuClosed #{&},
      #{$root}_menuOpened.enter-active #{&} {
        display: none;
      }
    }
  }

  &__menuLiExtraCol {
    width: 48px;
    overflow: hidden;

    text-align: center;

    @at-root {
      #{$root}_menuClosed #{&},
      #{$root}_menuOpened.enter-active #{&} {
        display: none;
      }
    }
  }
}
