.VersionMessageCreatedAt {
  $root: &;

  & {
    font-size: 13px;
    text-align: center;
    line-height: 16px;
    color: #834f83;
  }
}
