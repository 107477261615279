.ExportMessagesFormModal {
  $root: &;

  &__projectVersionList {
    display: flex;
    align-items: center;
  }

  &__projectVersionListLabel {
    margin-left: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
