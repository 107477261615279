.ActionsListPage {
  $root: &;

  &__title {
    margin-bottom: 4px;
  }

  &__description {
    font-size: #999;
    font-size: 85%;
  }

  &__navigationItem {
    display: inline-block;
    padding: 4px 4px;
    line-height: 14px;

    &_event {
      background-color: #eafdff;
      color: #34585c;
    }

    &_section {
      background-color: #ebfff6;
      color: #395749;
    }

    &_relateId {
      background-color: #fff8eb;
      color: #534a38;
    }
  }

  &__userCol {
    text-align: center;
  }

  &__unauthorizedUser {
    color: #a1a1a1;
  }
}
