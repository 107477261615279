.ProjectVersionColor {
  & {
    display: inline-flex;
    margin: 0px 4px 4px 0px;
    position: relative;

    height: 24px;
    border-radius: 4px;

    &_small {
      margin: 0;
      height: 18px;
    }

    &_isDeprecated {
      &::after {
        position: absolute;
        content: '';
        left: -3px;
        right: -2px;
        top: calc(50% - 1px);
        height: 2px;
        background-color: #f40;
        opacity: 0.75;
      }
    }

    @at-root {
      a:hover #{&} {
        filter: saturate(1.5) contrast(1.25);
        transition: filter 0.25s;
      }
    }
  }

  &__iconCol {
    width: 20px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    color: #fff;
    margin-left: -1px;
  }

  &__nameCol {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &__param {
    font-family: monospace, 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    line-height: 13px;
    font-size: 13px;
    letter-spacing: -0.8px;
    position: relative;

    line-height: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    min-width: 80px;
    max-width: 114px;
    padding: 0 4px;
  }
}
