.HomeDashboardPage {
  $root: &;

  &__stats {
    margin-bottom: 30px;
  }

  &__graph {
    display: inline-block;
    height: 320px;
    width: calc(50% - 16px);

    &:nth-child(odd) {
      margin-right: 32px;
    }
    margin-bottom: 32px;
  }
}
