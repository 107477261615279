.KitMenuActions {
  $root: &;

  &__button {
    border-radius: 24px !important;
    background-color: #f1f1f1 !important;
    color: #444 !important ;

    &:hover {
      background-color: #eaeaea !important;
      color: #444 !important ;
    }
  }
}
