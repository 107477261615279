@import 'styles/common.scss';

.KitAutoComplete {
  $root: &;

  &_error {
    .ant-select-selection-search-input {
      color: #7c2a2a;
    }
  }
}
