.MessageCountBadge {
  & {
    display: flex;
    color: #777;
  }

  &__icon {
    color: #999;
  }

  &__count {
    padding: 0 4px 0 12px;
    flex: 1;
  }
}
