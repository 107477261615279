.KitTitleActions {
  $root: &;

  & {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__title {
    display: flex;
    flex: 1;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  &__actions {
    & > * {
      margin-left: 12px;
    }
  }
}
