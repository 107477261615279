.KitNavItem {
  $root: &;

  & {
    display: inline-block;
    padding: 4px 12px;
  }

  &__link {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    display: flex;

    &,
    &:active {
      color: #0e91b9;
    }

    &:not(:disabled):not(.active):hover {
      color: #10a5d3;
    }

    &.active {
      color: #fb673a;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -12px;
        right: -12px;
        height: 2px;
        bottom: -14px;
        background-color: #fb673a;
      }
    }
  }

  &__text {
    flex: 0 0 auto;
  }

  &__text {
    flex: 1;
    padding: 0 8px 0 8px;
  }
}
