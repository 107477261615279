.RegisterPage {
  $root: &;

  &__container {
    text-align: center;
  }

  &__block {
    background-color: #fff;
    width: 480px;
    display: inline-block;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
  }

  &__header {
    width: 100%;
    height: 56px;
    padding: 4px 24px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;

    & > h1 {
      margin: 0;
      line-height: 0;
    }
  }

  &__body {
    padding: 40px 50px 40px 50px;
  }

  &__links {
    padding: 20px;
    text-align: center;
  }
}
