.MonitoringMessagesUpdatesPage {
  $root: &;

  &__item {
    margin-bottom: 20px;
  }

  &__date {
    margin-bottom: 8px;
    height: 24px;
    line-height: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    color: #555;
  }

  &__project-link {
    margin: 0 8px;
    display: inline-block;
    font-size: 12px;
  }

  &__detail {
    margin-bottom: 4px;
    display: flex;
  }

  &__messagesAmount {
    min-width: 80px;
    text-align: center;
  }

  &__messagesAmountValue {
    color: #fff;
    background-color: #25b1aa;
    padding: 2px 4px;
    border-radius: 3px;
    display: inline-block;
    font-weight: bold;
    line-height: 14px;
    font-size: 14px;
  }

  &__pagination {
    padding: 16px 12px;
    min-height: 32px + 16px * 2;
    text-align: right;
  }
}
