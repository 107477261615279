.ProjectMessagesPage {
  $root: &;

  &__project {
    padding-bottom: 10px;
    margin-bottom: 12px;
  }

  &__version {
    padding: 4px 0;
  }

  &__key {
    padding-bottom: 10px;
    margin-bottom: 12px;
  }

  &__defaultValue {
    border: 1px dashed #e1e1e1;
    padding: 4px 8px;
  }

  &__message {
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0 0 16px 0;

    &:hover {
      #{$root}__valueOverflow {
        color: #222;
        border: 1px dashed #c1c1c1;
      }

      #{$root}__actions {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  &__locale {
    flex: 0 0 auto;
  }

  &__value {
    flex: 1 1 auto;
    padding: 0 8px 2px 8px;
  }

  &__valueOverflow {
    height: 30px;
    padding: 0 8px 0px 8px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px dashed #e1e1e1;
    color: #616161;
    position: relative;

    &::after {
      content: '...';
      color: #bbb;
      text-align: center;
      line-height: 21px;
      font-weight: bold;
      font-size: 21px;
      height: 100%;
      width: 56px;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      background-color: #ffffffcc;
    }
  }

  &__actions {
    opacity: 0.1;
    transition: opacity 0.5s;
  }

  &__searchPlaceHolder {
    padding: 50px;
    text-align: center;
    color: #777;
  }
}
