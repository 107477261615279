@font-face {
  font-family: 'Audiowide-Regular';
  src: url('./Audiowide-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./Roboto-Regular.ttf');
}

.ant-reset-button {
  &,
  &:hover {
    background-color: #ffeb94bc;
  }
}

.ant-table-cell.ant-table-column-has-sorters .ant-table-column-sorters {
  width: 100%;

  & > span:first-child {
    flex: 1;
  }
}

.ant-input:read-only {
  color: #555;

  &:focus,
  &:hover,
  &.ant-input-focused {
    border: 1px solid #e1e1e1;
    box-shadow: none;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}

.badge-count-1 .ant-badge-count {
  background-color: #fff;
  color: #555;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.ant-descriptions-item-container {
  align-items: center;
}

.ant-input-affix-wrapper {
  .ant-input:read-only:focus,
  .ant-input:read-only:hover,
  .ant-input:read-only.ant-input-focused {
    border: 0 !important;
  }
}

.ant-select-item-option-content {
  white-space: normal;
  line-height: 1.1;
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto-Regular';
  color: #4e8796;
}

h1 {
  font-weight: bold;
  font-size: 19px;
  line-height: 19px * 1.1;
  margin-bottom: 20px;
}

h2 {
  font-weight: bold;
  font-size: 17px;
  line-height: 17px * 1.1;
}

h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px * 1.1;
}

.ant-select-block-options {
  .ant-select-selection-overflow-item,
  .ant-select-selection-item {
    width: 100%;
    justify-content: space-between;
  }
}

.ant-header-spin {
  position: absolute;
  right: 0;
  top: 0;
}
