.KeysUpdatesStatsChart {
  $root: &;

  & {
    height: 100%;
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__header {
    padding-bottom: 16px;
    flex-shrink: 0;
  }

  &__graphArea {
    flex: 1;
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__spin {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
  }
}
