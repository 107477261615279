.ImportMessagesFormModal {
  $root: &;

  &__projectVersionList {
    display: flex;
    align-items: center;
  }

  &__projectVersionListLabel {
    margin-left: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__uploadFilename {
    min-height: 18px + 4px * 2;
    padding: 4px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
