.UserInfo {
  & {
    margin-bottom: 24px;
    padding: 20px;
    border: 1px solid #e1e1e1;
  }

  &__versions {
    margin-bottom: 15px;
  }
}
