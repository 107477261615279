.KitFilterCol {
  & {
    padding: 8px;
    display: flex;
    min-width: 238px;
    align-items: center;
    justify-content: center;
  }

  &_handler {
    flex: 1;
    justify-content: flex-end;
  }

  &_switcher {
    min-width: 200px;
  }

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  .ant-select {
    width: 100%;
  }
}
