.KitFormContainer {
  & {
    padding: 20px;
    width: 100%;
    background-color: #01989808;
    border: 1px solid #01989820;
  }

  &__block {
    max-width: 720px;
  }
}
