.MessageDefaultKey {
  & {
    display: inline-block;
    min-height: 16px;
    word-wrap: break-word;
  }

  &__part {
    display: inline-block;
    max-width: 100%;
    border-radius: 3px;

    padding: 0 8px;
    position: relative;
    background-color: #fbf5e3;
    word-wrap: break-word;

    &:not(:last-child) {
      margin-right: 4px;
      margin-bottom: 4px;
    }

    &:nth-child(2n + 1) {
      background-color: #effff1;
    }

    &:nth-child(1) {
      background-color: #e0fafc;
    }

    &:nth-child(2) {
      background-color: #f5eaff;
    }

    &:nth-child(3) {
      background-color: #ddfff4;
    }

    &:nth-child(4) {
      background-color: #ffedd9;
    }

    &:not(:last-child):after {
      position: absolute;
      z-index: 1;
      top: 9px;
      right: -4px;
      content: '';
      background-color: #999;
      box-shadow: 0 0 4px 4px #ffffff;
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }
  }
}
