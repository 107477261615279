@import 'src/styles/common.scss';

.EditMessagesFormModal {
  $root: &;

  &__infoBlock {
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-bottom: 25px;
    display: flex;
  }

  &__infoBlockDescriptions {
    flex: 1 1 50%;
  }

  &__infoBlockComment {
    flex: 1 1 50%;
    display: flex;
  }

  &__commentTextarea {
    height: 100%;
    width: 100%;
  }

  &__infoBlockRow {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__infoBlockParam {
    display: inline-block;
    margin-right: 20px;
    width: 140px;
  }

  &__infoBlockValue {
    display: inline-block;
  }

  &__container {
    min-height: 240px;
  }

  &__versionParam {
    margin-bottom: 12px;
  }

  &__activeMessage {
    text-align: center;
  }

  &__activeMessageIcon {
    color: #6dcf6d;
  }

  &__cellDeprecated {
    font-size: 16px;
    color: #844;
    line-height: 16px;
    text-align: center;
  }

  &__deprecatedAgo {
    font-size: 12px;
    padding-top: 8px;
  }

  &__valueContainer {
    display: flex;
  }
  &__colValueTextarea {
    flex: 1;

    &_copyActive {
      box-shadow: 0 0 1px 1px #ffffff, 0 0 3px 3px #73dcf1;
    }

    &_copyMatch:not(#{&}_copyActive) {
      box-shadow: 0 0 1px 1px #ffffff, 0 0 3px 3px #8ce6c5;
    }

    &_warnMatch {
      box-shadow: 0 0 1px 1px #ffffff, 0 0 3px 3px #ffe988;
    }
  }

  &__valueAutoComplete {
    &.ant-select {
      flex: 1;
      width: 100%;
      height: 100%;
    }

    .ant-select-selector {
      width: 100%;
      height: 100%;
    }

    .ant-input.ant-select-selection-search-input {
      height: 100%;
    }
  }

  &__colValueHandle {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 0 0 10px;
  }

  // &__valueTextarea {
  //   min-width: 120px;
  //   // height: 108px !important;

  //   &_empty {
  //     box-shadow: 0 0 1px 1px #ff000066;
  //   }
  // }

  &__cellIsChecked {
    text-align: center;
  }

  &__btnIsChecked {
    @extend .clear-button;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #eee;
    text-align: center;
    line-height: 16px;
    padding: 8px 0;
    color: #818181;

    &:hover {
      background-color: #a4e68a;
    }

    &_is {
      background-color: #a4e68a;
      color: #fff;

      &:hover {
        background-color: #f1a092;
      }
    }
  }

  &__btnIsCheckedIcon {
    height: 16px;
    font-size: 16px;
  }
}
