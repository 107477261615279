@import 'styles/common.scss';

.KitItemAction {
  $root: &;

  &:not(:disabled):not(.ant-dropdown-menu-item-disabled):hover {
    filter: saturate(2);
  }

  &_delete {
    &:not(:disabled):not(.ant-dropdown-menu-item-disabled) #{$root}__icon {
      color: #fff;
      background-color: #c44e18;
    }
  }

  &_edit {
    &:not(:disabled):not(.ant-dropdown-menu-item-disabled) #{$root}__icon {
      color: #fff;
      background-color: #1595bd;
    }
  }

  &_versions {
    &:not(:disabled):not(.ant-dropdown-menu-item-disabled) #{$root}__icon {
      color: #fff;
      background-color: #13a856;
    }
  }

  &_info {
    &:not(:disabled):not(.ant-dropdown-menu-item-disabled) #{$root}__icon {
      color: #fff;
      background-color: #1b7a81;
    }
  }

  &__item {
    min-width: 120px;
    display: flex;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
    padding: 2px;
    width: 24px;
  }

  &__label {
    display: flex;
    align-items: center;
    flex: 1 1 0;
    padding: 2px 2px 2px 16px;
  }
}
