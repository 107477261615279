.OutsideLayout {
  height: 100%;
  width: 100%;
  padding: 5% 5% 20% 5%;

  &__content {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
