.UserBadge {
  & {
    display: inline-flex;
    justify-content: center;
    border-radius: 4px;
    min-width: 120px;
    line-height: 14px;
    font-size: 14px;
    color: #34585c;
  }

  &__colIcon {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    padding: 2px;
    height: 16px;
  }

  &__userId {
    padding: 2px 4px;
  }

  &__userName {
    padding: 2px 4px;
    font-size: 12px;
  }
}
