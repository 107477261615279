.SuccessToast {
  & {
    padding: 4px 4px 16px 4px;
  }

  &__header {
    margin-bottom: 12px;
  }
  &__icon {
    display: inline-block;
    width: 20px;
    margin-right: 4px;
    svg {
      fill: var(--white);
    }
  }
}
