.KitModuleLoader {
  $root: &;

  & {
    padding: 60px 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__block1 .ant-spin-dot-item {
    background-color: #41ff11;
  }

  &__block2 .ant-spin-dot-item {
    background-color: #11bcff;
  }

  &__block3 .ant-spin-dot-item {
    background-color: #9411ff;
  }

  &__block4 .ant-spin-dot-item {
    background-color: #ff111d;
  }

  &__block5 .ant-spin-dot-item {
    background-color: #ffdb11;
  }
}
