.KitFormCheckList {
  $root: &;

  & .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    & > span:last-child {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  & {
    border: 1px solid #eeeeeef1;
    border-radius: 3px;
  }

  &__checkAll {
    padding: 8px 8px;
    background-color: #f6f6f6f1;
  }

  &__checkAllLabel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__checkAllLabelTitle {
    flex: 1;
  }

  &__formItem {
    margin: 0 !important;
  }

  &__list {
    padding: 8px 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    padding: 8px 8px;
    // border-top: 1px solid #eeeeeef1;
  }
}
